<template>
  <PageContainer>
    <template v-slot:header>
      <v-col class="d-flex">
        <v-btn icon size="40px" @click="goBack">
          <v-icon color="primaryblack2" size="24px">mdi-chevron-left</v-icon>
        </v-btn>
        <span class="heading-2"> {{ form.id ? "Update" : "Create" }} organization </span>
      </v-col>
    </template>
    <v-progress-linear v-if="isLoading" indeterminate />
    <v-card class="organization-form">
      <v-col>
        <span class="heading-4 text-left">Brand details</span>
      </v-col>
      <v-divider />

      <v-col class="">
        <BaseInput
          :fullWidth="true"
          :labelActive="true"
          :value="form.name"
          class="my-2 mb17"
          fieldName="name"
          outlined
          placeholder="Name"
          required
          @change="changeFormField"
        />
        <BaseInput
          :fullWidth="true"
          :labelActive="true"
          :value="form.brandName"
          class="my-2 mb17"
          fieldName="brandName"
          outlined
          placeholder="Brand name"
          required
          @change="changeFormField"
        />
        <BaseInput
          :fullWidth="true"
          :labelActive="true"
          :value="form.appName"
          class="my-2 mb17"
          fieldName="appName"
          outlined
          placeholder="Application key"
          required
          @change="changeFormField"
        />
        <BaseInput
          :fullWidth="true"
          :labelActive="true"
          :value="form.brandDomain"
          class="my-2 mb17"
          fieldName="brandDomain"
          outlined
          placeholder="Brand domain"
          required
          @change="changeFormField"
        />
        <BaseInput
          :fullWidth="true"
          :labelActive="true"
          :value="form.brandAddress"
          class="my-2 mb17"
          fieldName="brandAddress"
          outlined
          placeholder="Brand address"
          required
          @change="changeFormField"
        />
        <BaseInput
          :fullWidth="true"
          :labelActive="true"
          :value="form.contactPhoneNumber"
          class="my-2"
          fieldName="contactPhoneNumber"
          outlined
          placeholder="Contact phone number"
          required
          @change="changeFormField"
        />
      </v-col>
      <v-col class="">
        <div class="d-flex justify-space-between align-center mb-2">
          <span>Logo</span>
          <MediaUploader
            :lite="true"
            :title="form.logoUrl ? 'Change' : 'Select'"
            @onUploaded="(files) => changeImage(files, 'logoUrl')"
          />
        </div>
        <div class="d-flex">
          <v-img :contain="true" :src="form.logoUrl" class="mr-2" max-height="100" />
        </div>
      </v-col>
      <v-col class="">
        <div class="d-flex justify-space-between align-center mb-2">
          <span>Logo expanded</span>
          <MediaUploader
            :lite="true"
            :title="form.logoExpandedUrl ? 'Change' : 'Select'"
            @onUploaded="(files) => changeImage(files, 'logoExpandedUrl')"
          />
        </div>
        <div class="d-flex">
          <v-img :contain="true" :src="form.logoExpandedUrl" class="mr-2" max-height="100" />
        </div>
      </v-col>
      <v-col class="">
        <div class="d-flex justify-space-between align-center mb-2">
          <span>Logo reduced</span>
          <MediaUploader
            :lite="true"
            :title="form.logoReducedUrl ? 'Change' : 'Select'"
            @onUploaded="(files) => changeImage(files, 'logoReducedUrl')"
          />
        </div>
        <div class="d-flex">
          <v-img :contain="true" :src="form.logoReducedUrl" class="mr-2" max-height="100" />
        </div>
      </v-col>

      <v-col>
        <span class="heading-4">Visit settings</span>
      </v-col>
      <v-divider />

      <v-col>
        <span>How far in to the future visit schedules can be?</span>
        <Select
          :hide-details="true"
          :items="scheduleHorizonLimitOptions"
          :value="form.scheduleHorizonLimit"
          fieldName="scheduleHorizonLimit"
          @change="changeFormField"
        />
      </v-col>
      <v-col>
        <span>Requests aren't allowed earlier than:</span>
        <Select
          :hide-details="true"
          :items="scheduleToStartLimitOptions"
          :value="form.scheduleToStartLimit"
          fieldName="scheduleToStartLimit"
          @change="changeFormField"
        />
      </v-col>
      <v-col>
        <span>Visit duration</span>
        <Select
          :hide-details="true"
          :items="slotDurationOptions"
          :value="form.slotDuration"
          fieldName="slotDuration"
          @change="changeFormField"
        />
      </v-col>
      <v-col>
        <span>Visit can't be canceled earlier than :</span>
        <Select
          :hide-details="true"
          :items="scheduleToCancelLimitOptions"
          :value="form.scheduleToCancelLimit"
          fieldName="scheduleToCancelLimit"
          @change="changeFormField"
        />
      </v-col>
      <v-col>
        <span class="heading-4">Theme colors</span>
      </v-col>
      <v-divider />

      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>Success </span>
        <div class="d-flex align-center">
          {{ themeColors.success }}
          <input
            :value="themeColors.success"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'success')"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>Error </span>
        <div class="d-flex align-center">
          {{ themeColors.error }}
          <input
            :value="themeColors.error"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'error')"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>errorlight1 </span>
        <div class="d-flex align-center">
          {{ themeColors.errorlight1 }}
          <input
            :value="themeColors.errorlight1"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'errorlight1')"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>primarylight3 </span>
        <div class="d-flex align-center">
          {{ themeColors.primarylight2 }}
          <input
            :value="themeColors.primarylight2"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'primarylight2')"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>primarylight3 </span>
        <div class="d-flex align-center">
          {{ themeColors.primarylight3 }}
          <input
            :value="themeColors.primarylight3"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'primarylight3')"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>primarylight3 </span>
        <div class="d-flex align-center">
          {{ themeColors.primarylight }}
          <input
            :value="themeColors.primarylight"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'primarylight')"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>primary </span>
        <div class="d-flex align-center">
          {{ themeColors.primary }}
          <input
            :value="themeColors.primary"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'primary')"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>primarylight3 </span>
        <div class="d-flex align-center">
          {{ themeColors.primaryblack }}
          <input
            :value="themeColors.primaryblack"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'primaryblack')"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>primarylight3 </span>
        <div class="d-flex align-center">
          {{ themeColors.primaryblack2 }}
          <input
            :value="themeColors.primaryblack2"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'primaryblack2')"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>primaryblack3 </span>
        <div class="d-flex align-center">
          {{ themeColors.primaryblack3 }}
          <input
            :value="themeColors.primaryblack3"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'primaryblack3')"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>primaryblack4 </span>
        <div class="d-flex align-center">
          {{ themeColors.primaryblack4 }}
          <input
            :value="themeColors.primaryblack4"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'primaryblack4')"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>primarylight3 </span>
        <div class="d-flex align-center">
          {{ themeColors.primaryblack5 }}
          <input
            :value="themeColors.primaryblack5"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'primaryblack5')"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-space-between align-center mb-2">
        <span>icon </span>
        <div class="d-flex align-center">
          {{ themeColors.icon }}
          <input
            :value="themeColors.icon"
            type="color"
            @change="(e) => changeFormThemeColorsField(e.target.value, 'icon')"
          />
        </div>
      </v-col>
      <v-divider />
      <v-card-actions class="d-flex justify-end">
        <PrimaryButton :loading="isSaving" text="Save" @click="performSave" />
      </v-card-actions>
    </v-card>
  </PageContainer>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { OrganizationsApi } from "@/api/organizations";
import MediaUploader from "@/components/media/MediaUploader.vue";
import BaseInput from "@/components/uikit/BaseInput.vue";
import PageContainer from "@/components/uikit/PageContainer.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import Select from "@/components/uikit/Select.vue";
import { useOrganizationStore } from "@/pinia-store/organization";
import { routesEnum } from "@/types/Routes.enum";
import {
  scheduleHorizonLimitOptions,
  scheduleToCancelLimitOptions,
  scheduleToStartLimitOptions,
  slotDurationOptions,
} from "@/utils/constants";

export default {
  name: "OrganizationEdit",
  data() {
    return {
      isLoading: false,
    };
  },
  components: { PrimaryButton, MediaUploader, BaseInput, Select, PageContainer },
  computed: {
    ...mapState(useOrganizationStore, ["form", "changeFormThemeColorsField", "isSaving"]),
    scheduleHorizonLimitOptions() {
      return scheduleHorizonLimitOptions;
    },
    themeColors() {
      return this.form.themeColors;
    },
    scheduleToStartLimitOptions() {
      return scheduleToStartLimitOptions;
    },
    slotDurationOptions() {
      return slotDurationOptions;
    },
    scheduleToCancelLimitOptions() {
      return scheduleToCancelLimitOptions;
    },
  },
  methods: {
    ...mapActions(useOrganizationStore, ["changeFormField", "saveForm", "setForm"]),
    async performSave() {
      await this.saveForm();
    },
    goBack() {
      this.$router.push({ name: routesEnum.organizations });
    },
    changeImage(files, fieldName) {
      this.changeFormField(files[0].url, fieldName);
    },
  },
  async mounted() {
    console.log(this.$route);
    if (this.$route.params.id) {
      this.isLoading = true;
      const item = await OrganizationsApi.find(this.$route.params.id);
      this.setForm(item);
      this.isLoading = false;
    }
    console.log("Organization mounted");
  },
};
</script>

<style lang="scss" scoped>
.organization-form {
  max-width: 450px;
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
}
</style>
